.h1 {
    font-family: Ageo;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0px;

}

.h2 {
    font-family: Ageo;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
}

.h3 {
    font-family: Ageo;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
}

.h4 {
    font-family: Ageo;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
}