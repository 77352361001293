.table-default {
    @apply min-w-full;
}

.table-default tbody {
    @apply bg-white divide-y divide-gray-200;
}

.table-text {
    font-family: Ageo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    @apply text-black;
}

.table-default tbody tr:nth-child(odd) {
    @apply bg-white;
}

.table-default tbody tr:nth-child(even) {
    @apply bg-semigray;
}

.table-default tbody tr:nth-child(odd) td:nth-child(1) {
    @apply border-l-4 border-white;
}

.table-default tbody tr:nth-child(even) td:nth-child(1) {
    @apply border-l-4 border-semigray;
}

.table-default tbody tr:hover td:nth-child(1) {
    @apply border-l-4 border-tailgreen;
}