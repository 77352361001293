.btn-large {
    font-size: 18px;
    padding-top: 18px;
    padding-bottom: 18px;
}

.btn-medium {
    font-size: 15px;
    padding-top: 14px;
    padding-bottom: 14px;
}

.btn-small {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
}

button.btn-prymary {
    background: linear-gradient(72.48deg, #5DBA62 15.67%, #E0EDDC 184.41%);
    color: #FFFFFF;
    font-weight: bold;
    line-height: 28px;
    font-style: normal;
    border-radius: 40px;
    letter-spacing: 0.75px;
    @apply ring-tailgreen;
}

.btn-prymary:hover {
    background: #5DBA62;
}

.btn-prymary:focus {
    background: #5DBA62;
    outline: 2px solid transparent;
    outline-offset: 2px;
    @apply outline-none ring-2 ring-offset-2;
}

.btn-prymary:active {
    background: #104936;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
}

.btn-prymary:disabled {
    background: #5DBA62;
    opacity: 0.5;
}

.btn-secondary {
    color: #5DBA62;
    border: 2px solid #5DBA62;
    box-sizing: border-box;
    font-weight: bold;
    line-height: 28px;
    font-style: normal;
    border-radius: 40px;
    letter-spacing: 0.75px;
    @apply ring-tailgreen;
}

.btn-secondary:hover {
    color: #104936;
    border: 2px solid #104936;
    box-sizing: border-box;
}

.btn-secondary:focus {
    color: #5DBA62;
    border: 2px solid #5DBA62;
    box-sizing: border-box;
    @apply outline-none ring-0;
}

.btn-secondary:active {
    color: #104936;
    border: 2px solid #104936;
    box-sizing: border-box;
}

.btn-secondary:disabled {
    color: #104936;
    border: 2px solid #5DBA62;
    box-sizing: border-box;
    opacity: 0.5;
}